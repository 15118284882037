'use strict';

// NOTE: The TopBarApp is only intended to be used on pages that don't already have angular.

var angular = require('angular');
var Bonfire = window.Bonfire;
var _ = require('lodash');

var moment = require('bonfire-common/js/bf-moment');
var bfAngularConfig = require('bonfire-common/js/bf-angular-config');
var bfConstants = require('bonfire-common/js/bf-constants');

var topBarApp = angular.module('TopBarApp', [
    require('bonfire-common/js/angular-foundation'),
    require('bonfire-common/js/bf-common'),
]);

topBarApp.config([
    '$compileProvider',
    '$httpProvider',
    '$tooltipProvider',
    '$modalProvider',
    function ($compileProvider, $httpProvider, $tooltipProvider, $modalProvider) {
        bfAngularConfig.config($compileProvider, $httpProvider, $tooltipProvider, $modalProvider);
    },
]);

topBarApp.run([
    '$rootScope',
    function ($rootScope) {
        bfAngularConfig.run($rootScope, Bonfire, moment);

        $rootScope.constants = $rootScope.bfConstants = bfConstants;
    },
]);

// Components

topBarApp.component('bfTopBar', require('bonfire-common/js/bf-top-bar'));

// Controllers

topBarApp.controller('MainCtrl', [
    function ($rootScope, $q, CommonDataService, AlertService) {
        var main = this;

        main.init = function (initData) {
            _.assign(main, initData.commonViewData);

            main.pageName = initData.pageName;

            // The TopBarApp is only intended to be used on pages that don't already have angular,
            // So we can assume that either:
            // (1) The user is authenticated (and therefore will not login via this top bar); OR
            // (2) This is an old page with the jqModal login modal.
            main.isLoginModal = true;
        };
    },
]);

topBarApp.directive('calculateDropdown', () => ({
    link: window.dispatchEvent(new Event('topBarLoaded')),
}));
